import React, { useState } from "react"
import styled from "@emotion/styled"
import { injectIntl, Link } from "gatsby-plugin-intl"
import ReactPlayer from "react-player"

const BlackFridayMatelasVideoBannerWrapper = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  color: #fff;
  padding: 0;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`

const LeftSection = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  position: relative;
  flex-direction: column;
  background: #141211;
`

const BlackFridayTextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  flex-direction: column;
  color: #fff;
  max-width: 600px;
  padding: 10px 30px 30px;

  @media (max-width: 1200px) {
    padding: 0px 20px 20px;
  }
`

const BlackFridayLogo = styled.img`
  width: 100%;
  margin: 0px;
  object-fit: contain;
`

const BlackFridayLastDay = styled.div`
  font-size: 40px;
  line-height: 1.2;
  color: #fff;
  font-family: "Museo";
  font-weight: 900;
  text-align: center;
  margin-bottom: 10px;
  text-transform: uppercase;

  @media (max-width: 1200px) {
    font-size: 32px;
  }

  @media (max-width: 992px) {
    font-size: 28px;
  }

  @media (max-width: 767px) {
    font-size: 24px;
  }

  @media (max-width: 380px) {
    font-size: 21px;
  }
`

const BlackFridayText = styled.div`
  font-size: 28px;
  line-height: 1;
  color: #fff;
  font-family: "Museo";
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;

  span {
    font-size: 48px;
    font-family: "Museo";
    font-weight: 900;
    background: #fff;
    color: #262626;
    padding: 5px 10px;
    margin: 0px 7px;
    line-height: 1;

    @media (max-width: 1200px) {
      font-size: 40px;
      margin: 0px 5px;
    }

    @media (max-width: 992px) {
      font-size: 28px;
      padding: 5px;
    }

    @media (max-width: 767px) {
      font-size: 21px;
    }

    @media (max-width: 380px) {
      font-size: 20px;
    }
  }

  sup {
    margin-right: 4px;
  }

  @media (max-width: 1200px) {
    font-size: 24px;
  }

  @media (max-width: 992px) {
    font-size: 21px;
  }

  @media (max-width: 767px) {
    font-size: 16px;
  }

  @media (max-width: 380px) {
    font-size: 14px;
  }
`

const RightSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  flex-direction: column;
  color: #262626;
  padding: 0;
`

const AbsoluteButton = styled(Link)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  cursor: pointer;
  z-index: 2;
`

const ButtonTitle = styled.span`
  display: none;
`

const VideoWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  overflow: hidden;
  aspect-ratio: 16/9;

  > div {
    position: relative;
    display: flex;
    z-index: 1;
  }

  iframe,
  video {
    width: 125% !important;
    height: 100% !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0px;
    border: 0px;

    @media (max-width: 1600px) {
      width: 140% !important;
    }

    @media (max-width: 1250px) {
      width: 150% !important;
    }

    @media (max-width: 992px) {
      width: 200% !important;
    }

    @media (max-width: 720px) {
      width: 240% !important;
    }

    @media (max-width: 600px) {
      width: 100% !important;
      position: relative;
      top: 0px;
      left: 0px;
      transform: translate(0px, 0px);
    }
  }

  @media (max-width: 600px) {
    position: relative;
  }
`

const BannerImage = styled.img`
  width: 100%;
  height: 100%;
  margin: 0px;
  object-fit: cover;
  position: absolute;
  z-index: ${({ loading }) => (loading === "image" ? "2" : "1")};
  aspect-ratio: 16/9;
`

const TopSection = styled.div`
  display: flex;
  background: #d7edf6;
  width: 100%;
  align-items: center;
  position: relative;
  cursor: pointer;
  color: #fff;
  min-height: 550px;

  @media (max-width: 1400px) {
    min-height: 480px;
  }

  @media (max-width: 1200px) {
    min-height: 450px;
  }

  @media (max-width: 1100px) {
    min-height: 400px;
  }

  @media (max-width: 600px) {
    min-height: auto;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;

  @media (max-width: 850px) {
    flex-direction: column;
  }
`

const Button = styled(Link)`
  font-family: "Museo";
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  font-size: 18px;
  padding: 10px 20px;
  line-height: 1;
  width: 100%;
  position: relative;
  border: 1px solid #fff;
  color: #fff;

  :hover {
    color: #fff;
  }
`

const BlackFridayMatelasVideoBanner = ({ intl, data }) => {
  const [videoLoading, setVideoloading] = useState(true)

  const handleLoading = () => {
    setVideoloading(false)
  }

  return (
    data && (
      <BlackFridayMatelasVideoBannerWrapper>
        <LeftSection className="HomeVideoBanner">
          {data.video_section_text.button_text &&
            data.video_section_text.button_url && (
              <AbsoluteButton to="/shop/offres/">
                <ButtonTitle>{data.video_section_text.button_text}</ButtonTitle>
              </AbsoluteButton>
            )}
          <BlackFridayTextWrapper>
            <BlackFridayLogo src="https://static.percko.com/uploads/1a8adc35-2d37-4b28-9332-99fa4ea7fb0f.gif" />
            <BlackFridayLastDay
              dangerouslySetInnerHTML={{
                __html: "Derniers jours",
              }}
            />
            {/* <BlackFridayText
              dangerouslySetInnerHTML={{
                __html: "pour libérer votre dos",
              }}
            /> */}
            <BlackFridayText
              dangerouslySetInnerHTML={{
                __html:
                  "Jusqu'à <span>-60%</span> sur le 2<sup>e</sup> produit",
              }}
            />
            {data.video_section_text.button_text &&
              data.video_section_text.button_url && (
                <ButtonWrapper>
                  <Button to="/shop/offres/">J'en profite</Button>
                </ButtonWrapper>
              )}
          </BlackFridayTextWrapper>
        </LeftSection>
        <RightSection>
          <TopSection>
            {data.video_section_text.button_text &&
              data.video_section_text.button_url && (
                <AbsoluteButton to="/shop/offres">
                  <ButtonTitle>
                    {data.video_section_text.button_text}
                  </ButtonTitle>
                </AbsoluteButton>
              )}
            <VideoWrapper>
              <BannerImage
                src="https://static.percko.com/uploads/50d9167b-800d-4df0-9d44-cd786e28a4d4.jpg"
                loading={videoLoading ? "image" : "video"}
              />
              <ReactPlayer
                url="https://www.percko.com/videos/percko.com/eur-fr/home-page/home_page_top_black_friday_978x550.mp4"
                playing={true}
                controls={false}
                muted={true}
                loop={true}
                width="100%"
                height="100%"
                playsinline={true}
                playsInline
                onPlay={() => {
                  handleLoading()
                }}
              />
            </VideoWrapper>
          </TopSection>
        </RightSection>
      </BlackFridayMatelasVideoBannerWrapper>
    )
  )
}

export default injectIntl(BlackFridayMatelasVideoBanner)
